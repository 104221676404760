/* @import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@400;600;700&family=Work+Sans:wght@400;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@600&family=Work+Sans:wght@400;600&display=swap');

:root {
	--bs-primary-color: #ffcd00;
	--bs-primary-color-2: #e895a8;
	--bs-primary-color-sand: #fefaf1;
}

body {
	margin: 0;
	font-family: 'Work Sans';
}

.primary-color {
	color: var(--bs-primary-color);
}

.primary-color-curacao {
	color: #629db7;
}

.color-tan-dark {
	color: #ae9969;
}

.primary-color2 {
	color: var(--bs-primary-color-2);
}

.primary-background-color {
	background-color: var(--bs-primary-color);
}

.send-email-bulk-modal .ant-modal-content {
	background-color: #fefaf1 !important;
}

/* .title-font {
	font-family: 'Unbounded';
} */

/* .cta-text-style {
	font-family: 'Unbounded';
	font-weight: 700;
} */

.title-text-style {
	font-family: 'Unbounded';
	font-weight: 600;
}

/* .subtitle-text-style {
	font-family: 'Unbounded';
	font-weight: 400;
} */

.ant-table {
	border: 1px solid rgba(29, 29, 27, 0.1);
}

.ant-table-row {
	cursor: pointer;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
	background-color: #1d1d1b;
	color: white;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
	background-color: var(--bs-primary-color);
}

.ant-menu-submenu .ant-menu-submenu-title {
	color: white !important;
}

.ant-menu-submenu .ant-menu-submenu-title:hover {
	color: #1d1d1b !important;
}

.ant-notification-notice {
	background-color: var(--bs-primary-color);
}

#components-drag-sorting tr.drop-over-downward td {
	border-bottom: 3px dashed var(--bs-primary-color) !important;
}

#components-drag-sorting tr.drop-over-upward td {
	border-top: 3px dashed var(--bs-primary-color) !important;
}

.cursor-pointer {
	cursor: pointer;
}

.icon-spin {
	-webkit-animation: icon-spin 1s infinite linear;
	animation: icon-spin 1s infinite linear;
}

@-webkit-keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.collapse-base .ant-collapse-header {
	padding: 8px !important;
	padding-left: 32px !important;
}

.collapse-base .ant-collapse-arrow {
	padding-top: 8px !important;
	left: 12px !important;
}

.font-size-12 {
	font-size: 12px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
	margin-left: 0px !important;
}

.PhoneInputInput {
	border: white;
}

.PhoneInputInput:focus-visible {
	border: white;
	outline: white;
}

.react-resizable-handle {
	margin-right: 6px;
}

.react-colorful__last-control {
	border-radius: 0px !important;
}

.settings-menu li {
	font-size: 12px;
	height: 30px !important;
	line-height: 30px !important;
	margin-bottom: 4px !important;
}

.month-div:hover {
	background: rgba(255, 205, 0, 0.2);
}

.add-event {
	display: none;
}

.month-div:hover .add-event {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	/* color: white; */
	background: rgba(255, 205, 0, 0.8);
	width: 24px;
	height: 24px;
	cursor: pointer;
	font-size: 16px;
	margin-left: 4px;
}

.month-div:hover .add-event:hover {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	/* color: white; */
	background: rgba(255, 205, 0, 0.8);
	width: 24px;
	height: 24px;
	cursor: pointer;
	font-size: 16px;
	margin-left: 4px;
	background: rgba(255, 205, 0, 0.4);
}

.month-div-closed {
	background: rgba(232, 149, 168, 0.2);
}

.old-date {
	background: rgba(29, 29, 27, 0.06);
}

.edit-section-drawer {
	display: none;
}

.section-drawer:hover .edit-section-drawer {
	display: block;
}

.base-drawer-products thead {
	font-size: 10px !important;
}

.base-drawer-products tbody {
	font-size: 12px !important;
}

.base-drawer-products .ant-tag {
	font-size: 10px !important;
}

.preview-products thead {
	font-size: 10px !important;
}

.preview-products thead .ant-table-cell {
	font-size: 10px !important;
	font-weight: bold;
}

.preview-products tbody {
	font-size: 12px !important;
}

.preview-products .ant-tag {
	font-size: 10px !important;
}

.preview-products .ant-table-cell {
	background-color: transparent !important;
}

.custom-drawer .ant-drawer-content {
	background: #fefaf1;
}

.custom-drawer .ant-drawer-body,
.custom-drawer .ant-drawer-body > div {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.custom-drawer .ant-drawer-body::-webkit-scrollbar,
.custom-drawer .ant-drawer-body::-webkit-scrollbar > div {
	display: none;
}

.custom-modal .ant-modal-content {
	background: #fefaf1;
}

.custom-modal .ant-modal-header {
	background: transparent;
}

.custom-select-borderless .ant-select-selector {
	padding: 0px !important;
}

.custom-input-borderless.ant-input-sm {
	padding: 0px !important;
}

.custom-section-with-border {
	padding: 16px;
	border: 1px solid rgba(29, 29, 27, 0.1);
	border-radius: 4px;
	background: white;
}

p {
	margin-bottom: 0px !important;
}

.first-child-no-margin :first-child {
	margin-top: 0px;
}

.selected-date-multi {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border: 1px solid #ae9969 !important;
}

.date-with-event {
	background: white !important;
}

.weekly-date-item:hover {
	border: 1px solid #ae9969 !important;
}

.special-table thead {
	font-size: 12px !important;
}

.special-table tbody {
	font-size: 12px !important;
}

.special-table .ant-tag {
	font-size: 12px !important;
}

.ant-dropdown-menu-submenu {
	max-height: 400px !important;
	overflow-y: auto !important;
}

// .special-table .ant-table-title {
// 	background: #def0f6;
// }

#components-drag-sorting .ant-table-title {
	background: #def0f6;
}

#google-btn button {
	padding: revert !important;
}

#google-btn button div {
	padding: revert !important;
	margin-right: 8px !important;
	margin-left: 8px !important;
	padding: 0px !important;
	display: flex;
	align-items: center;
}

.availabilities:hover {
	background: rgba(255, 205, 0, 0.8);
	color: white;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.input-number-mews input {
	text-align: center !important;
	font-weight: bold;
}

.ant-dropdown {
	background: white;
}

.ant-dropdown-menu-title-content {
	display: flex !important;
	align-items: center !important;
}

.list-item-custom {
	padding: 0px !important;
}

.ant-dropdown-menu-submenu-title {
	display: flex;
}

.base-section {
	/* background-color: var(--bs-primary-color-sand); */
	/* background-color: rgba(234, 239, 201, 0.4); */
}

.base-section .base-title-edit {
	display: none;
}

.base-section:hover .base-title-edit {
	display: block;
}

/* .base-section:hover .base-title {
	color: var(--bs-primary-color);
} */

#user-submenu .ant-menu-submenu-title {
	padding: initial;
}

.underline-hover:hover {
	text-decoration: underline;
}

.page-height a {
	text-decoration: underline;
}

.custom-ant-input {
	box-sizing: border-box;
	margin: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.88);
	font-size: 14px;
	line-height: 1.5714285714285714;
	list-style: none;
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	background-color: #fff;
	background-image: none;
	border-width: 1px;
	border-style: solid;
	border-color: #d9d9d9;
	border-radius: 6px;
	transition: all 0.2s;
	height: 32px;
}

.custom-ant-input-hover:hover {
	border-color: var(--bs-primary-color);
	border-inline-end-width: 1px;
}

.custom-ant-input-hover:focus {
	border-color: var(--bs-primary-color);
	box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
	border-inline-end-width: 1px;
	outline: 0;
}

.custom-submenu-maxheight ul {
	box-shadow: none;
	overflow: scroll;
	max-height: 400px;
}

.spinning-scroll {
	overflow: scroll;
}

.display-on-hover {
	display: none;
}

.parent-display-on-hover:hover .display-on-hover {
	display: flex;
}

.ellipsis-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.shake {
	animation: shake-animation 0.25s ease 1s 4;
	/* transform-origin: 50% 50%; */
}

.stripes-style {
	background: repeating-linear-gradient(-45deg, #e895a850, #e895a850 5px, white 5px, white 15px) !important;
}

@keyframes shake-animation {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px);
	}
	75% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

button.ant-btn.is-active {
	background: rgba(0, 0, 0, 0.06) !important;
}
